// import {
//   discover_3,
//   discover_4,
//   discover_9,
//   discover_10,
//   discover_5,
//   discover_6,
//   discover_7,
//   discover_8,
//   discover_1,
//   discover_2,
//   logo_1,
//   logo39,
//   logo_38,
//   logo_2,
//   logo_5,
//   logo_7,
//   logo_8,
//   logo_9,
//   logo_12,
//   logo_13,
//   logo_14,
//   logo_15,
// } from "../../assets/home";
const cards = [
  {
    id: 1,
    // //image: discover_1,
    title: "Rotiki",
    score: "9.3",
    to: "/Games",
    view: 6993,
    path: "Rotiki",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=rotiki&lang=en_GB&practice=1&channel=desktop&demo=2",
    rel: "noreferrer",
    about:
      "Ziel des Spiels ist es, fünf oder mehr Symbole zu kombinieren, um eine Gewinngruppe zu bilden..",
  },
  {
    id: 2,
    // //image: discover_2,
    title: "Captan Glum",
    score: "9.8",
    to: "/Games",
    path: "Captan_glum",
    view: 8198,
    rel: "noreferrer",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=piratehunter&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "Zu seiner Hilfe verfügt er über ein permanentes Wild-Symbol, das bei allen Drehungen im Basisspiel auf den Walzen 2 schwimmt..",
  },
  {
    id: 3,
    //image: discover_3,
    title: "Golden Siris",
    to: "/Games",
    view: 7377,
    path: "Golden_siris",
    score: "9.1",
    rel: "noreferrer",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=merlinsgrimoire&lang=en_GB&practice=1&channel=desktop&demo=2",
    about: "Der Goldene Osiris birgt die Geheimnisse des Glücks..",
  },
  {
    id: 4,
    //image: discover_4,
    title: "Nova7s",
    score: "9.4",
    to: "/Games",
    path: "Nova7s",
    view: 5043,
    rel: "noreferrer",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=journeyofflame&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "Der Spielautomat Merlin's Magic Respins ist eine Fortsetzung des beliebten Spielautomaten Merlin's Millions.",
  },
  {
    id: 5,
    //image: discover_5,
    title: "Fire Joker",
    score: "9.9",
    path: "Fire_joker",
    to: "/Games",
    view: 9344,
    rel: "noreferrer",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ankhofanubis&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "Der teuflische Feuer-Joker dient als Wild-Symbol, das an jeder Position auf allen drei Walzen erscheinen kann..",
  },
  {
    id: 6,
    //image: discover_6,
    title: "Sticky Joker",
    to: "/Games",
    score: "9",
    view: 7494,
    rel: "noreferrer",
    path: "Sticky_joker",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=firejoker&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "Sticky Joker ist ein klassischer 3-Walzen-Slot mit großen Gewinnmöglichkeiten.",
  },
  {
    id: 7,
    //image: discover_7,
    title: "Captain Xends",
    score: "9.4",
    to: "/Games",
    view: 6009,
    rel: "noreferrer",
    path: "Captain_xends",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=christmasjoker&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "In diesem Spiel schafft die Funktion bis zu 32.768 Gewinnmöglichkeiten",
  },
  {
    id: 8,
    //image: discover_8,
    title: "Odin Story",
    score: "9.7",
    to: "/Games",
    view: 8302,
    rel: "noreferrer",
    path: "Odin_story",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=captxenosadventure&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "Story of Odin ist ein 5x4-Slot im nordischen Stil des Entwicklers Spinomenal, bei dem Sie bis zu 100 Freispiele mit garantiert gestapelten Wilds gewinnen können.",
  },

  {
    id: 9,
    score: "8.9",
    //image: discover_9,
    title: "Viking",
    view: 3238,
    path: "Viking",
    about:
      "Einfach ausgedrückt ist der Viking Runecraft-Slot für Hochrisikospieler gedacht.",
    to: "/Games",
    rel: "noreferrer",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=odinprotectorofrealms&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: 10,
    //image: discover_10,
    title: "Merlins grimoire",
    view: 8372,
    path: "Merlins_grimoire",
    score: "9.9",
    to: "/Games",
    rel: "noreferrer",
    iframeUrl:
      "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=vikingrunecraft100&lang=en_GB&practice=1&channel=desktop&demo=2",
    about:
      "Merlin's Grimoire ermöglicht es Spielern, mehrere Zaubersprüche zu wirken, darunter Freispiele, expandierende Symbole und teilende Symbole.",
  },
];

const links = [
  //1
  {
    id: 16,
    //image: logo_15,
    title: "",
    number: 520,
    to: "https://www.traffiqtraq.com/track/fb7e9334-337f-41de-b717-7defe9ccf9e2?type=ppc&s1=DE52LUN310&s4=",
    rel: "noreferrer",
    score: 9.9,
    bonus01: "Herzlich Willkommen!",
    bonus02: "2000 € BONUS ",
    bonus03: " + 200 Freispiele!",
  },
  //2

  {
    id: 15,
    //image: logo_14,
    title: "",
    number: 410,
    score: 9.8,
    bonus01: "Erhalten Sie 100% ",
    bonus02: " bis zu 600 €",
    bonus03: "+100 Freispiele",
    to: "https://www.traffiqtraq.com/track/05d9df4e-704f-4a0e-9acc-409d41789af6?type=ppc&s1=DE52CLE3204&s4=",
    rel: "noreferrer",
  },
  //3
  {
    id: 14,
    //image: logo_13,
    title: "",
    bonus01: "5000 €",
    bonus02: "+ 300 FREI SPIELE",
    to: "https://www.traffiqtraq.com/track/134c00d7-df68-46dc-a3cc-dbad12d4cb00?type=ppc&s1=DE52ROT300&s4=",
    rel: "noreferrer",
    number: 374,
    score: 9.7,
  },
  //4

  {
    number: 239,
    score: 9.6,
    id: 7,
    //image: logo_7,
    title: "",
    bonus01: "Einzahlung €50.    ",
    bonus02: "Spiel mit €100 ",
    to: "https://media1.bosspartners.com/redirect.aspx?pid=8553&bid=1693&clickid=",
    rel: "noreferrer",
  },
  //5
  {
    id: 13,
    //image: logo_12,
    title: "",
    number: 240,
    score: 9.5,
    bonus01: "100% bis zu 500€",
    bonus02: "+200FS  ",
    bonus03: " + 1 Bonuskrabbe",
    to: "https://media.vinylcasino.com/redirect.aspx?pid=2959&lpid=8&bid=1480&clickid=",
    rel: "noreferrer",
  },

  //6
  {
    id: 2,
    //image: logo_2,
    title: "",
    number: 237,
    score:9.4,
    bonus01: "Bonus bis zu 300 € ",
    bonus03: " + 150 Freispiele",
    to: "https://www.traffiqtraq.com/track/fc86d3c3-dd65-4b5f-af31-6533cdef9d5d?type=ppc&s1=DE52QBT320&s4=",
    rel: "noreferrer",
  },

  //7
  {
    id: 39,
    //image: logo39,
    number: 483,
    score: 9.3,
    title: "",
    to: "https://5gringos.com/de/?mid=36449_343893&fluid=58fb1d30-0fe8-4c27-9f25-417319e82395&clickid=fde39b1f-f00f-4565-bdb5-176c387b8047",
    rel: "noreferrer",
    bonus01: "Bis zu 1000€ ",
    bonus02: "in 3 Bonussen",
  },
  //8
  {
    id: 5,
    //image: logo_5,
    title: "",
    number: 863,
    score: 9.2,
    bonus01: "Bis zu 800€ +  ",
    bonus02: "+ 300 FS in 4 Boni",
    to: "https://www.traffiqtraq.com/track/0718ad81-fbde-4c62-a2ca-2050382e0e86?type=ppc&s1=DE52MYE330&s4=",
    rel: "noreferrer",
  },
  //9
  {
    id: 11,
    //image: logo_8,
    title: "",
    number: 594,
    score: 9.1,
    to: "https://www.traffiqtraq.com/track/ff906efe-8500-4466-83a0-d83a92b29bc3?type=ppc&s1=DE52SGO320&s4=",
    rel: "noreferrer",
    bonus01: "100% bis zu 500€  ",
    bonus02: "+ 200 FS",
  },
  //10
  {
    id: 38,
    number: 853,
    score: 9.1,
    bonus01: "100% bis zu 500€  ",
    bonus02: "+ 200 FS",
    bonus03: " + 1 Bonus Crab",
    //image: logo_38,
    title: "",
    to: "https://www.traffiqtraq.com/track/57b8322b-36dc-46a7-b9d8-de6a294fffa7?type=ppc&s1=DE59POO300&s4=",
    rel: "noreferrer",
  },

  //11
  {
    number: 594,
    score: 8.9,
    id: 9,
    //image: logo_9,
    title: "",
    to: "https://media1.bosspartners.com/redirect.aspx?pid=8554&bid=2134&clickid=",
    rel: "noreferrer",
    bonus01: "100% bis zu €1500  ",
    bonus02: "+ 100 Freispiele ",
  },
];

export { cards, links };
