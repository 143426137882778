import { useState } from "react";
import { Link } from "react-router-dom";
// import logo from "../assets/home/logo.webp";
import Container from "./Container";
import { FaBars } from "react-icons/fa";

function Navbar({ user }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className="py-5 z-40">
      <Container>
        <div className="flex items-center justify-between h-16 ">
          <div className="flex gap-4 items-center w-full justify-between">
            <Link to="/">
              FreeLuckyGame
              {/* <img className="h-[100px] w-[100px] " src={logo} alt="Workflow" /> */}
            </Link>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4 text-white">
                <Link
                  to="/"
                  className="hover:bg-[#CC9900] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Heim
                </Link>

                <Link
                  to="About"
                  className="hover:bg-[#CC9900] hover:text-white px-3 py-2 rounded-md text-sm font-medium capitalize"
                >
                  Über uns
                </Link>
                <Link
                  to="/privacy"
                  className="hover:bg-[#CC9900] hover:text-white px-3 py-2 rounded-md text-sm font-medium capitalize"
                >
                  Datenschutzrichtlinie
                </Link>
                <Link
                  to="/terms-and-conditions"
                  className="hover:bg-[#CC9900] hover:text-white px-3 py-2 rounded-md text-sm font-medium capitalize"
                > Bedingungen und Konditionen</Link>
                <Link
                  to="/Contact"
                  className="hover:bg-[#CC9900] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Rufen Sie uns an
                </Link>
                {/* {user === 'user' ? (
                  <Link
                    to='/Games/1'
                    className='hover:bg-[#CC9900] hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                    target='_blank'
                  >
                    Spiele
                  </Link>
                ) : (
                  <a
                    href='https://www.top10bestonlinecasinos.co.uk/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Spiele
                  </a>
                )} */}
              </div>
            </div>
          </div>

          <div className="-mr-2 flex md:hidden ">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Oscail an príomh-roghchlár</span>

              <FaBars size={24} />
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden transition-all " id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-white">
              <Link
                to="/"
                className="hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Heim
              </Link>
              <Link
                to="About"
                className="hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                über uns
              </Link>

              <Link
                to="/privacy"
                className="hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Datenschutzrichtlinie
              </Link>
              {/* {user === 'user' ? (
                <a
                  href='https://www.top10bestonlinecasinos.co.uk/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Spiele
                </a>
              ) : (
                <Link
                  to='/Games'
                  target='_blank'
                  rel='noreferrer'
                  className='hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Spiele
                </Link>
              )} */}

              <Link
                to="/terms-and-conditions"
                className="hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Bedingungen und Konditionen
              </Link>

              <Link
                to="Contact"
                className="hover:bg-primary-base hover:bg-[#CC9900] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                Rufen Sie uns an
              </Link>
            </div>
          </div>
        )}
      </Container>
    </nav>
  );
}
export default Navbar;
