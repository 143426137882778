import React from 'react'
import Container from '../Container'
import SectionTitle from './SectionTitle'
import card from '../../assets/home/card.webp'
import { Link } from 'react-router-dom'

export default function HowItWorks({ user }) {
  return (
    <section className='my-14'>
      <Container>
        <SectionTitle />
        <div className='bg-opacity-30 px-2 sm:px-8 py-8 sm:py-14 rounded-md mt-8'>
          <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 place-items-center lg:gap-14 gap-8'>
            <div className='text-center flex flex-col items-center justify-center'>
             
              <h1 className='font-bold text-lg py-4 text-white'>
                Erfahren Sie mehr über uns
              </h1>
              <p className='text-white'>
                {user === 'user'
                  ? ` Willkommen bei  freeluckygame.com , wo die Welt der Unterhaltung
                  auf die Spannung des Spielens trifft. Wir sind Ihr kosmisches
                  Tor zu einer Welt aufregender kostenloserEine.
                  Casino-Erlebnisse
                  mit einer großen Auswahl an Spielen,
                  darunter kostenloses Casino, Stud Poker, Double Exposure
                  Blackjack und das mysteriöse Mystery Joker 6000. eine Reise in
                  den Raum der Gaming-Perfektion. Unsere Mission bei  freeluckygame.com 
                   ist es, ein unvergleichliches Spielerlebnis zu bieten, das
                  Innovation, Spannung und Transparenz vereint. Wir sind bestrebt,
                  eine Umgebung zu schaffen, in der alle Spieler ihre bevorzugten
                  kostenlosen Casinospiele unbesorgt genießen und neue
                  Unterhaltungsinteressen entdecken können.`
                  : `Willkommen bei  freeluckygame.com , wo die Welt der Unterhaltung
    auf die Spannung des Spielens trifft. Wir sind Ihr kosmisches
    Tor zu einer Welt aufregender kostenloser
    Social-Casino-Erlebnisse mit einer großen Auswahl an Spielen,
    darunter kostenloses Social-Casino, Stud Poker, Double Exposure
    Blackjack und das mysteriöse Mystery Joker 6000. eine Reise in
    den Raum der Gaming-Perfektion. Unsere Mission bei  freeluckygame.com   ist es, ein unvergleichliches Spielerlebnis zu bieten, das
    Innovation, Spannung und Transparenz vereint. Wir sind bestrebt,
    eine Umgebung zu schaffen, in der alle Spieler ihre bevorzugten
    kostenlosen Social-Casinospiele unbesorgt genießen und neue
    Unterhaltungsinteressen entdecken können.`}
              </p>

              {/* {user === 'user' ? (
                <a
                  href='https://www.top10bestonlinecasinos.co.uk/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='capitalize mt-6 bg-[#CC9900] hover:bg-[#cc9900bc] transition-colors px-14 py-3 rounded-sm font-bold text-white'
                >
                  Fan ceangailte
                </a>
              ) : (
                <Link to='/Games' target='_blank' rel='noreferrer'>
                  <button className='capitalize mt-6 bg-[#CC9900] hover:bg-[#cc9900bc] transition-colors px-14 py-3 rounded-sm font-bold text-white'>
                    Fan ceangailte
                  </button>
                </Link>
              )} */}
            </div>
            <div>
              {/* <img
                src={card}
                alt='card'
                className='h-[200px] w-[400px] sm:h-[500px]'
              /> */}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
