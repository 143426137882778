import { cards, links } from "./data";
import { Link } from "react-router-dom";
import Container from "../Container";
import SectionTitle from "./SectionTitle";
import { FaStar } from "react-icons/fa";
import { useState } from "react";

const DiscoverCard = ({ card }) => {

  return (
    <div className="flex overflow-hidden cursor-pointer">
      <Link
        to={`/Games/${card?.path}`}
        className="flex h-full sm:h-[130px] flex-col md:flex-row items-center justify-between w-[90%] mx-auto mt-4 bg-white rounded-xl shadow-lg p-5 sm:p-3"
      >
        <div className="flex flex-row w-full sm:w-3/4">
          <div className="md:w-1/5  xl:w-[180px]  rounded-tl-lg w-[150px] h-[150px]">
            {/* <img
              src={card.image}
              alt="discover_image"
              className="w-full h-full object-cover "
            /> */}
          </div>
          <div className="flex w-full items-center flex-col sm:flex-row">
            {/* score */}
            <div className="w-full sm:w-1/3 text-center py-1 sm:py-3">
              <h3 className="text-sm text-slate-600 m-auto text-center">
                unsere Punktzahl
              </h3>
              <span className="text-xl sm:text-3xl lg:text-4xl xl:text-5xl text-[#255376]  font-medium">
                {card.score}
              </span>
            </div>
            {/* stars */}
            <div className="w-full sm:w-1/3 flex flex-col md:justify-around items-center h-auto ">
              <div className="flex">
                <FaStar className="text-yellow-500 mr-1" />
                <FaStar className="text-yellow-500 mr-1" />
                <FaStar className="text-yellow-500 mr-1" />
                <FaStar className="text-yellow-500 mr-1" />
                <FaStar className="text-yellow-500 mr-1" />
              </div>
              <h3 className=" text-slate-600text-xs text-center">
                Ansichten <span className="text-sm">({card.view})</span>
              </h3>
              <a
                href={card.to}
                className="text-[#255376] text-xs font-medium underline text-center"
              >
                Schnellansicht
              </a>
            </div>
            {/* about */}
            <div className="w-full sm:w-1/3 py-3 text-center px-2 ">
              <p className="text-xs sm:text-sm ">{card.about}</p>
            </div>
          </div>
        </div>

        {/* button */}
        <div className="w-full sm:w-1/4 h-[80px] sm:h-full rounded-tr-lg text-white font-semibold text-xl flex items-center justify-center bg-gradient-to-r from-yellow-400 to-orange-500 text-center">
          Spielen
        </div>
      </Link>
    </div>
  );
};

const DiscoverLinks = ({ card }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <>
      <div className="flex overflow-hidden cursor-pointer">
        <a
          href={card.to}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col md:flex-row items-center justify-between w-[90%] lg:w-[80%] mx-auto mt-4 bg-white rounded-xl shadow"
        >
          <img
            src={card.image}
            alt="discover_image"
            className=" bg-black w-full md:w-1/4 lg:w-[230px] h-[110px] lg:h-[120px] sm:h-[150px] md:h-full object-contain rounded-tl-lg"
          />
          <div className="md:w-1/4 lg:w-1/5 text-center py-2 lg:py-3">
            <h3 className="text-sm lg:text-base  text-slate-600 m-auto">
              unsere Punktzahl
            </h3>
            <span className="text-xl lg:text-4xl text-[#008df7] font-medium">
{card.score}            </span>
          </div>
          <div className="flex flex-col md:justify-around items-center md:h-auto md:w-1/4 lg:w-1/5">
            <div className="flex">
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500 mr-1" />
            </div>
            <h3 className=" text-slate-600 text-xs lg:text-sm text-center">
              Ansichten  ({card.number}+)
            </h3>
            <a
              href={card.to}
              className="text-[#008df7] text-xs lg:text-sm font-medium underline text-center"
            >
              Schnellansicht
            </a>
          </div>
          <div className="md:w-1/4 lg:w-1/5 py-3 text-center">
            <h3 className="text-[10px]   text-slate-600">Willkommensbonus</h3>
            <h3 className="text-[10px]  lg:text-sm font-medium">
             {card.bonus01}
            </h3>
            <h3 className="text-[10px]  lg:text-sm font-medium">
            {card.bonus02}
            </h3>
            <h3 className="text-[10px]  lg:text-sm font-medium">
            {card.bonus03}
            </h3>
          </div>
          <div
            className={`w-full md:w-1/3 lg:w-1/4 sm:h-full h-[70px] rounded-tr-lg text-white font-semibold text-xl flex items-center justify-center ${
              clicked
                ? "bg-white text-yellow-400 border-[3px] border-yellow-400"
                : "bg-gradient-to-r from-yellow-400 to-orange-500"
            }`}
            onClick={handleClick}
          >
            {clicked ? "Besuchen Sie uns wieder" : "Bonus erhalten"}
          </div>
        </a>
      </div>
      <div className="w-[90%]  h-[15px] sm:h-[20px]  lg:w-[80%] bg-white bg-opacity-20 m-auto rounded-b-xl mt-0 ">
        <p className="text-[8px] sm:text-[11px] lg:text-sm text-[#B4B4B8] text-center">
          Nur ab 18 Personen.
        </p>
      </div>
    </>
  );
};

export default function Discover({ user }) {
  // console.log(user)
  return (
<section className="my-2 sm:my-8 md:my-14">
      <Container>
        <div>
          <SectionTitle title="" />
          <div className="flex flex-col items-center justify-center h-full text-center">
            <p className="text-amber-400 font-bold md:text-base text-base">
              <h1>Kostenlose Casino-Spiele</h1>
            </p>
            <p className="text-white font-bold md:text-4xl text-xl mt-2">
              Lassen Sie Ihr Glück los und gewinnen Sie groß
            </p>
            <p className="text-white font-thin md:text-sm text-sm mt-2">
              Tauchen Sie KOSTENLOS in das ultimative soziale Casino-Abenteuer
              ein
            </p>
          </div>

          {user === "user" ? (
            <div className="grid grid-cols-1">
              {links.map((card) => (
                <div key={card.id} className="flex flex-col">
                  <DiscoverLinks card={card} />
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-0 sm:gap-4 ">
              {cards.map((card) => (
                <>
                  <DiscoverCard card={card} key={card.id} />
                </>
              ))}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}
