

import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import MyRoutes from "./MyRoutes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import axios from "axios";
import LoadingPage from "./Pages/LoadingPage";
import CookieConsent from "react-cookie-consent";

function loadGTM() {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-KG3MT8FS";
  script.async = true;
  document.head.appendChild(script);
}

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   loadGTM();
  // }, []);

  // function fetchAndSendIP() {
  //   setLoading(true);
  //   const params = Object.fromEntries(searchParams);

  //   axios
  //    .post("https://freeluckygame.com/api/setLanguage", {
  //   //  .post("http://localhost:3030/api/setLanguage", {
  //       currentPath: location.pathname,
  //       params: params
  //     })
  //     .then((res) => {
  //       const { message } = res.data;
  //       setUser(message);
  //       setLoading(false);
  //     })
  //     .catch((error) => console.error("Error with backend:", error));
  // }

  // useEffect(() => {
  //   fetchAndSendIP();
  // }, [location.pathname, searchParams]);

  return (
    <div className="bg-[#2C3639]">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Navbar user={user} />
          <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            cookieName="mySiteCookieConsent"
            style={{
              background: "gray",
              position: "fixed",
              left: "50%",
              bottom: "20px",
              transform: "translateX(-50%)",
              width: "90%",
              maxWidth: "600px",
              padding: "15px",
              boxSizing: "border-box",
              zIndex: 1000,
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              borderRadius: "8px",
            }}
            buttonStyle={{
              color: "#4e503b",
              fontSize: "14px",
              padding: "10px 20px",
              cursor: "pointer",
              backgroundColor: "#ffffff",
              border: "none",
              borderRadius: "4px",
              marginTop: "10px",
            }}
            contentStyle={{
              flex: "1 0 300px",
              margin: "0",
            }}
            expires={150}
            onAccept={() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "consent_given",
              });
            }}
          >
            <p style={{ margin: "0 0 10px 0", fontSize: "14px" }}>
              Diese Website verwendet Cookies. Bitte akzeptieren Sie Cookies für
              ein besseres Nutzererlebnis.
            </p>
          </CookieConsent>

          <MyRoutes user={user} />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
