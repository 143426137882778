import React from 'react'
import card from '../../assets/home/edssa.webp'
import Container from '../Container'
import SectionTitle from './SectionTitle'
// import { Link } from 'react-router-dom'

const NewsCard = ({ title, content }) => {
  return (
    <div className='w-[320px] px-4 py-6 bg-slate-50 rounded-md news_card_shadow mb-4'>
      <p className='text-[#5B6469] font-bold text-[15px]'>{title}</p>
      <div className='pt-7 text-[13px] flex items-center gap-2'>
        <div>
          <h1 className='font-medium'>{content}</h1>
        </div>
      </div>
    </div>
  )
}

export default function News() {
  return (
    <section className='my-14'>
      <Container>
        <div>
          <SectionTitle classes='text-center' />
          <div className=' bg-opacity-30 px-8 py-14 rounded-md mt-8'>
            <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 place-items-center lg:gap-14 gap-8'>
              {/* <div>
                <img src={card} alt='card' />
              </div> */}
              <div className='text-center flex flex-col items-center justify-center'>
                <h2 className='text-amber-600'>
                  Kostenlose Social-Casino-Spiele
                </h2>
                <h1 className='font-bold text-lg py-4 text-white'>
                  Wir stellen Ihnen Ihre bevorzugten kostenlosen
                  Social-Casinospiele vor
                </h1>

                <div className='grid grid-cols-1 2xl:grid-cols-2 place-items-center gap-8 mb-4 lg:gap-14'>
                  <NewsCard
                    title='Poker Stud Casino'
                    content='Casino Stud Poker ist eine beliebte Variante des traditionellen Pokers, die speziell auf dem Fünf-Karten-Spiel basiert. Bei diesem Spiel treten die Spieler gegen den Dealer an, nicht gegen andere Spieler, was einen wichtigen Unterschied zum klassischen Poker darstellt. Double Exposure Blackjack ist eine Variante des klassischen kostenlosen Social-Casino-Kartenspiels Blackjack. Bei diesem Spiel teilt der Dealer die ersten beiden Karten offen aus, was den Spielern mehr Informationen und Einfluss auf ihre Strategie bietet.'
                  />
                  <NewsCard
                    title='Joker-Geheimspiel 6000'
                    content='Mystery Joker 6000 ist ein Video-Spielautomatenspiel, eine Art kostenloses Social-Casino-Spiel, das für seine Einfachheit und die Chance auf große Preise bekannt ist. Spieler drehen die Walzen und wenn sie bestimmte Symbole in bestimmten Kombinationen anordnen, gewinnen sie Preise.'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
